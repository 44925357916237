import React from 'react';
import './Navbar.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import * as ROUTES from './constants/routes';
import { Link } from "react-router-dom";

import { AuthUserContext } from './Session';

import SignOutButton from './SignOut';

const cNavbar = ({ authUser }) => (
  <div><AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer></div>
);

const NavigationAuth = () => (
  <Navbar>
    <Navbar.Brand className="nav-brand" href="/">CG</Navbar.Brand>
    <Nav className="mr-auto">
      <Nav className="nav-instructions">[spacebar]  to generate new chords [enter] to hear them</Nav>
    </Nav>
    <Link className="navbar-link" to={ROUTES.ACCOUNT}>
      Account
    </Link>
    <SignOutButton />
  </Navbar>
);

const NavigationNonAuth = () => (
  <Navbar>
    <Navbar.Brand className="nav-brand" href="/">CG</Navbar.Brand>
    <Nav className="mr-auto">
      <Nav className="nav-instructions">[spacebar]  to generate new chords [enter] to hear them</Nav>
    </Nav>
    <Link className="navbar-link" to={ROUTES.SIGN_IN}>
      login
    </Link>
    <Link className="navbar-link" to={ROUTES.SIGN_UP}>
      signup
    </Link>
  </Navbar>  
);

export default cNavbar;