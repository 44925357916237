import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../constants/routes';
const PasswordForgetPage = () => (
  <div>
    <h1 className="text-center form-header">Forgot Password?</h1>
    <p className="text-center">No worries, we'll email you a reset link</p>
    <PasswordForgetForm />
  </div>
);
const INITIAL_STATE = {
  email: '',
  error: null,
};
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
      <form className="form-signin" onSubmit={this.onSubmit}>
      <div class="form-group">
        <input name="email" value={email} type="text"  onChange={this.onChange} id="inputEmail" className="form-control" placeholder="Email address" required autofocus />      
      </div>
      {error && <p>{error.message}</p>}
      <button disabled={isInvalid} class="btn btn-lg btn-primary btn-block" type="submit">Reset My Password</button>
        
      </form>
    );
  }
}
const PasswordForgetLink = () => (
  <p className="text-center p-signin">
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);
export default PasswordForgetPage;
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
export { PasswordForgetForm, PasswordForgetLink };