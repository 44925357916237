import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../constants/routes';
import { ModalRoute } from 'react-router-modal';
const SignInPage = () => (
  <div>
    <h1 className="text-center form-header">Login</h1>
    <SignInForm />
    {/** <SignInGoogle /> **/}
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
        <div>
          <hr />
          <form class="form-signin" onSubmit={this.onSubmit}>
            <div class="form-group">
              <input name="email" value={email} type="text"  onChange={this.onChange} id="inputEmail" className="form-control" placeholder="Email address" required autofocus />
              
            </div>

            <div class="form-group">
              <input name="password" value={password} type="password" onChange={this.onChange} id="inputPassword" className="form-control" placeholder="Password" required />
              
            </div>

            <button disabled={isInvalid} class="btn btn-lg btn-primary btn-block btn-user" type="submit">Login</button>
            {error && <p>{error.message}</p>}
            <hr />
          </form>
        </div>  

    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid)
          .set({
            email: socialAuthUser.user.email
          });
      })
      .then(socialAuthUser => {
        this.setState({ error: null });

    this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

export default SignInPage;
export { SignInForm, SignInGoogle };
