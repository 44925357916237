import React from 'react';
import ChordList from './ChordList';
import Navbar from './cNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './App.css';
import 'react-router-modal/css/react-router-modal.css';
import Tone from 'tone';
import { ModalContainer } from 'react-router-modal';
import { ModalRoute } from 'react-router-modal';

import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import SignUpPage from './SignUp';
import SignInPage from './SignIn';
import PasswordForgetPage from './PasswordForget';
import AccountPage from './Account'
import { withAuthentication } from './Session';

import * as ROUTES from './constants/routes';

const corpusData = require('./chord_corpus.json');
const chordData = require('./chord_info.json');
const vexChord = require('./chords.json');
const keys = Object.keys(corpusData);

let chords = [{
  id: 0,
  chordVal : '',
  key: '',
  type: '',
  isLocked : false,
  steps: [],
  vexChord : {
      "chord": [],
      "position": 0,
    } ,
}, {
  id: 1,
  chordVal : '',
  key: '',
  type: '',
  isLocked : false,
  steps: [],
  vexChord : {
      "chord": [],
      "position": 0,
    } ,
}, {
  id: 2,
  chordVal : '',
  key: '',
  type: '',
  isLocked : false,
  steps: [],
  vexChord : {
      "chord": [],
      "position": 0,
    } ,
}, {
  id: 3,
  chordVal : '',
  key: '',
  type: '',
  isLocked : false,
  steps: [],
  vexChord : {
      "chord": [],
      "position": 0,
    } ,
}] 

const chordProgressions = [
  ['I','V','vi','IV'],
  ['I','vi','IV','V'],
  ['I','IV','vi','V'],
  ['I','V','IV','V'],
  ['I','V6','vi','V'],
  ['I','ii7','I6','IV'],
  ['I','iii','vi','IV'],
  ['I','V','vi', 'I'],
  ['I','V7','IV', 'I'],
  ['I','bVI','V', 'I'],
  ['I','bVII','IV','I'],
  ['IV','V','V6','vi'],
  ['IV','I6','V', 'IV'],
  ['IV','I6','ii', 'IV'],
  ['IV','iv','I', 'IV'],
  ['V','viio','vi', 'V'],
  ['vi','V','IV','V'],
  // ['IV','I6','V'],
  // ['IV','I6','ii'],
  // ['I','V','vi'],
  // ['I','V7','IV'],
  // ['V','viio','vi'],
  // ['IV','iv','I'],
  // ['I','bVI','V'],
  // ['I','V','IV','bVII','I'],
  // ['I','V','vi','iii','IV'],
  // ['IV','ii','I','V','I'],
  ['iim7', 'V7', 'Imaj7', "I6"],
  ['Imaj7', 'vi7', 'iim7', 'V7']
]

const keyModifier = { 
  'C' : 0,
  'C#' : 1,
  'D' : 2,
  'D#' : 3,
  'E' : 4,
  'F' : 5,
  'F#' : 6,
  'G' : 7,
  'G#' : 8,
  'A' : 9,
  'A#' : 10,
  'B' : 11
}

const chordTranslate = {
  'I': 'C',
  '#I': 'C#',
  'bII': 'Cb',
  'II': 'D',
  '#II': 'D#',
  'bIII': 'Eb',
  'III': 'E',
  'IV': 'F',
  '#IV': 'F#',
  'bV': 'Gb',
  'V': 'G',
  '#V': 'G#',
  'bVI': 'Ab',
  'VI': 'A',
  '#VI': 'A#',
  'bVII': 'Bb',
  'VII': 'B',
}

const stepTranslate = {
  1: 'C4',
  2: 'C#4',
  3: 'D4',
  4: 'D#4',
  5: 'E4',
  6: 'F4',
  7: 'F#4',
  8: 'G4',
  9: 'G#4',
  10: 'A4',
  11: 'A#4',
  12: 'B4'
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.lockHandler = this.lockHandler.bind(this);
    this.generateChords = this.generateChords.bind(this);
    this.playChords = this.playChords.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
    this.stepsToNotes = this.stepsToNotes.bind(this);
    this.getVexChord = this.getVexChord.bind(this);
    this.generateBasicChords = this.generateBasicChords.bind(this);

    this.state = {
      authUser : null,
      chords
    };
  }

  /* Space bar press handler */
  keyPressHandler(event){
    // event.preventDefault();
    if(event.keyCode === 32) {
      this.generateBasicChords()
      console.log(this.state.chords);
    } else if (event.keyCode === 13 && window.location.pathname != '/login') {
      this.playChords()
    }
  }
  componentDidMount(){
    document.addEventListener("keydown", this.keyPressHandler, false);
    this.generateBasicChords();

    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        authUser
          ? this.setState({ authUser })
          : this.setState({ authUser: null });
      },
    );
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.keyPressHandler, false);
    this.listener();
  }

  lockHandler(chordId) {
    let tmp = this.state.chords;
    tmp[chordId].isLocked = !tmp[chordId].isLocked;
    this.setState({
      chords : tmp
    })
  }

  generateBasicChords() {
    let newChords = []
    let newChordProg = chordProgressions[Math.floor(Math.random()*chordProgressions.length)];
    let x = 0;

    newChordProg.forEach((chord) => {
      console.log('Chord: ' + chord);

      newChords.push({
        id : x,
        chordVal : chord,
        key : chordTranslate[chordData[chord].degree],
        type : chordData[chord].type,
        isLocked : false,
        steps : chordData[chord].computerSteps,
        notes : this.stepsToNotes(chordData[chord].computerSteps),
        vexChord : this.getVexChord(chordTranslate[chordData[chord].degree], chordData[chord].type)
      })
      x++;
    });
    this.setState({
      chords: newChords
    })
  }

  playChords() {
    let chords = this.state.chords;
    var synth = new Tone.PolySynth(6, Tone.Synth, {
      oscillator : {
        type : "sine"
      }
    }).toMaster();
    
    var part = new Tone.Part(function(time, note){
      synth.triggerAttackRelease(note, "8n", time);
    }, 
      [[0, chords[0].notes], 
      ["0:1", chords[1].notes],
      ["0:2", chords[2].notes],
      ["0:3", chords[3].notes],
      ["0:4", chords[0].notes]],
    );
    part.start();
    Tone.Transport.bpm.value = 69;
    Tone.Transport.start();
  }

  stepsToNotes(steps) {
    let notes = []
    steps.forEach((step) => {
      notes.push(stepTranslate[step]);
    });
    return notes;
  }

  getVexChord(key, type) {
    if (type === "min") {
      type = "m";
    } else if (type === "maj") {
      type = "";
    } else if (type === "min7") {
      type = "m7";
    } else if (type === "o") {
      type = "dim";
    } else if (type === "min6") {
      type = "m6";
    } else if (type === "s4") {
      type = "sus"
    } else if (type === "d7") {
      type = "7"
    } else if (type === "a") {
      type = "+"
    } else if (type === "min9") {
      type = "m9"
    } else if (type === "b5") {
      type = "-5"
    }
    let v = vexChord[key + type];
    if (typeof v !== "undefined") {
      return v;
    } else {
      return {
        chord: [],
        position: 0,
      };
    }
  }

  // engine for generating new chords
  generateChords() {
    let newChords = []
    let isFirstChord = true;

    this.state.chords.forEach((chord) => {
      if (isFirstChord) {
        if (chord.isLocked) {
          newChords.push(chord);
        } else {
          let newChord = keys[Math.floor(Math.random()*keys.length)];
          console.log(chordData[newChord]);
          console.log(chordData[newChord].degree);
          let k = chordTranslate[chordData[newChord].degree];
          let t = chordData[newChord].type;
          newChords.push({
            id : chord.id,
            chordVal : newChord,
            key : k,
            type : t,
            isLocked : false,
            steps : chordData[newChord].computerSteps,
            notes : this.stepsToNotes(chordData[newChord].computerSteps),
            vexChord : this.getVexChord(k, t),
          });
        }
        isFirstChord = false;
      } else {
        if (chord.isLocked) {
          newChords.push(chord);
        } else {
          const prevChord = newChords[chord.id - 1];
          let possibleChords = corpusData[prevChord.chordVal];
          let newChord = possibleChords[Math.floor(Math.random()*possibleChords.length)];
          // TypeError: chordData[newChord] is undefined
          // TODO: catch this error
          console.log(chordData[newChord]);
          console.log(chordData[newChord].degree);
          let k = chordTranslate[chordData[newChord].degree];
          let t = chordData[newChord].type;
          newChords.push({
            id : chord.id,
            chordVal : newChord,
            key : k,
            type : t,
            isLocked : false,
            steps : chordData[newChord].computerSteps,
            notes : this.stepsToNotes(chordData[newChord].computerSteps),
            vexChord : this.getVexChord(k, t),
          });
        }
      }
    });
    this.setState({
      chords : newChords
    })
  }



  render() {
    return (
      <div className="App">
        <Container fluid="true">
          <cNavbar />
          <Router>
            <div>
              <Navbar authUser={ this.state.authUser } />
              <Switch>
              <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
              <ModalRoute exact path={ROUTES.PASSWORD_FORGET} parentPath='/' component={PasswordForgetPage}></ModalRoute>
              <ModalRoute exact path={ROUTES.SIGN_UP} parentPath='/' component={SignUpPage}></ModalRoute>
              <ModalRoute exact path={ROUTES.SIGN_IN} parentPath='/' component={SignInPage}></ModalRoute>
              </Switch>
            </div>
            <ModalContainer />
          </Router>
          <Row className="chords" fluid="true">
            <Col style={{ "padding-left": 0, "padding-right": 0 }}>
              <ChordList chords={this.state.chords} lockHandler={this.lockHandler}/>
            </Col>
          </Row>  
        </Container>
      </div>
    ) 
  }
}

export default withAuthentication(App);
