import React from 'react';
import Col from 'react-bootstrap/Col';

const progressions = [
	'2-5-1',
	'Jazz Turnaround',
	'Shoop'
]

class Explore extends React.Component {
	render() {
		return (
			<Col className="explore-bar">
				<h1>Explore</h1>
				<ul className="explore-list">
				{progressions.map((progression) => {
					return <li>{ progression }</li>
				})}
				</ul>
			</Col>	
		)
	}
}

export default Explore;