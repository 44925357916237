import React from 'react';
import Chord from './Chord';
import Explore from './Explore';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class ChordList extends React.Component {
	render() {
		return (
			<Container fluid="true">
				<Row>
					{this.props.chords.map((chord) => {
						return <Chord chord={chord} key={chord.id} steps={chord.steps} lockHandler={this.props.lockHandler}/>
					})}
					{/* <Explore /> */}
				</Row>
			</Container>
		)
	}
}

export default ChordList;