import React from 'react';
import Col from 'react-bootstrap/Col';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUnlock } from '@fortawesome/free-solid-svg-icons';
// import { faLock } from '@fortawesome/free-solid-svg-icons';
// import tinyColor from 'tinycolor2';
import * as vexchords from 'vexchords';

const bgColors = [
  '#17183b',
  '#1f487e',
  '#376996',
  '#6290c8',
]

const textColor = '#FFFFFF';

class Chord extends React.Component {
  componentDidUpdate(){
    const sel = 'vexchord' + this.props.chord.id;
    document.getElementById(sel).innerHTML = "";

    vexchords.draw('#' + sel, this.props.chord.vexChord,
    { 
      width : 180,
      height : 220,
      strokeColor : textColor,
      defaultColor: textColor,
    });
  }  

  render() {
    let id = 'vexchord' + this.props.chord.id;

    // if (this.props.chord.isLocked) {
    // 	icon = faLock;
    // } else {
    // 	icon = faUnlock;
    // }

    return (
      <Col className="chord-box d-flex" style={{ backgroundColor:  bgColors[this.props.chord.id%4], 'color' : textColor}}>
          <div className="chord-display justify-content-center align-self-center" id={id}></div>
          <div className="chord-val">
            <p className="chord-val-key">{this.props.chord.key}</p>
            <p className="chord-val-type">{this.props.chord.type}</p>
          </div>  
          
          {/*<FontAwesomeIcon icon={icon} className="btn-lock" onClick={() => this.props.lockHandler(this.props.chord.id)}/>*/}
      </Col>
    ) 
  }
}

export default Chord;
